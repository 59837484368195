@import '../../style/themes/default';
@import '../../style/mixins/index';

@result-prefix-cls: ~'@{ant-prefix}-result';

.@{result-prefix-cls} {
  padding: 48px 32px;
  // status color
  &-success &-icon > .anticon {
    color: @success-color;
  }

  &-error &-icon > .anticon {
    color: @error-color;
  }

  &-info &-icon > .anticon {
    color: @info-color;
  }

  &-warning &-icon > .anticon {
    color: @warning-color;
  }

  // Exception Status image
  &-image {
    width: 250px;
    height: 295px;
    margin: auto;
  }

  &-icon {
    margin-bottom: 24px;
    text-align: center;

    > .anticon {
      font-size: 72px;
    }
  }

  &-title {
    color: @heading-color;
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }

  &-subtitle {
    color: @text-color-secondary;
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }

  &-extra {
    margin-top: 32px;
    text-align: center;
    > * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-content {
    margin-top: 24px;
    padding: 24px 40px;
    background-color: @background-color-light;
  }
}

@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;